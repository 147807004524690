import { PLURALS } from "common/constants"

const replaceElementInArrayByIndex = (array: any[], replaceElement: any, index: number) => {
    return array.map((el, elIndex) => {
        if (elIndex === index) {
            return replaceElement
        }
        return el
    })
}

const toBase64 = (file): Promise<string | ArrayBuffer> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result.toString().replace(/^.*,/, ""))
        reader.onerror = error => reject(error)
    })

const isImageStoredLocally = (str: string): boolean => {
    return str.includes("/media/")
}

async function convertExistingImageIntoBase64(imagePath: string): Promise<string | ArrayBuffer> {
    let base64String: string | ArrayBuffer
    try {
        const response = await fetch(imagePath)

        const blob = await response.blob()

        const reader = new FileReader()
        reader.onloadend = () => (base64String = reader.result)
        reader.readAsDataURL(blob)
    } catch (error) {
        console.error("Error fetching or converting image:", error)
    }

    return base64String
}

const getBase64Image = (imgUrl: string): Promise<string> => {
    return new Promise(resolve => {
        const img = new Image()
        img.setAttribute("crossOrigin", "anonymous") //
        img.src = imgUrl
        img.onload = () => {
            const canvas = document.createElement("canvas")
            canvas.width = img.width
            canvas.height = img.height
            const ctx = canvas.getContext("2d")
            ctx.drawImage(img, 0, 0)
            const dataURL = canvas.toDataURL("image/png")
            const normalizedBaseUrl = dataURL.replace(/^data:image\/(png|jpg);base64,/, "")

            resolve(normalizedBaseUrl)
        }
    })
}

const checkImageResolution = (file, widthResolution, heightResolution) => {
    return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = window.URL.createObjectURL(file)
        img.onload = () => {
            const width = img.naturalWidth
            const height = img.naturalHeight

            if (width <= widthResolution && height <= heightResolution) {
                resolve(true)
            } else {
                resolve(false)
            }
        }
        img.onerror = error => reject(error)
    })
}

const reorder = (list, startIndex, endIndex) => {
    const result = [...list]
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}

const includesTranslation = (name: string) =>
    [
        "title",
        "content",
        "question",
        "summary",
        "short_summary",
        "summary_subtitle",
        "response",
        "free_response_prompt",
        "not_applicable_label",
        "not_applicable_prompt",
        "subtitle",
        "assignment_description",
        "typing_prompt"
    ].includes(name)

const isObject = obj => obj === Object(obj) && !Array.isArray(obj) && typeof obj !== "function"

// ! used for pluralizing words
// ! ex: "word" - "words"
const pluralize = (val: number | Object, word?: string, plural = `${word}s`) => {
    const internal = (num, word, plural = `${word}s`) => ([1, -1].includes(Number(num)) ? word : plural)
    if (typeof val === "object") return (num, word) => internal(num, word, val[word])
    return internal(val, word, plural)
}

// ! auto pluralize function used for handling some specific words
// ! ex:  "person" - "people", just add plurals to "PLURALS" object
const autoPluralize = pluralize(PLURALS)

const scrollToTop = () => window.scrollTo({ top: 0 })

function shallowEqual(obj1: any, obj2: any): boolean {
    if (typeof obj1 !== typeof obj2) {
        return false
    }

    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)

    if (keys1.length !== keys2.length) {
        return false
    }

    for (const key of keys1) {
        if (typeof obj1[key] === "object" || typeof obj2[key] === "object") {
            continue
        }

        if (obj1[key] !== obj2[key]) {
            return false
        }
    }

    return true
}

export {
    replaceElementInArrayByIndex,
    toBase64,
    checkImageResolution,
    getBase64Image,
    isImageStoredLocally,
    convertExistingImageIntoBase64,
    includesTranslation,
    reorder,
    isObject,
    pluralize,
    autoPluralize,
    scrollToTop,
    shallowEqual
}
